import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { AssetModel } from '@asset/models/asset.model';
import { HttpDataState } from '@core/types/http-data-state';
import { AuthStore } from '@core/state/auth.store';
import { UsersRolesService } from '@ddb/services/users-roles.service';
import { RoleName } from '@ddb/enums/role-name';
import { ProjectService } from '@ddb/services/project.service';
import { AssetService } from '@asset/services/asset.service';
import { filterNullish } from '@core/utils/filter-nullish';
import { createChunkedRequests } from '@core/utils/chunk-requests';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
  Observable,
  switchMap,
  map,
  first,
  combineLatest,
  catchError,
  EMPTY,
} from 'rxjs';

type CollectionStoreState = HttpDataState<AssetModel[]>;

@Injectable({ providedIn: 'root' })
export class CollectionStore extends ComponentStore<CollectionStoreState> {
  constructor(
    private readonly authStore: AuthStore,
    private readonly usersRoles: UsersRolesService,
    private readonly project: ProjectService,
    private readonly asset: AssetService,
    private readonly notification: NotificationService,
  ) {
    super({ status: 'idle' });
  }

  assets(): Observable<CollectionStoreState> {
    return this.select((state) => state);
  }

  readonly loadAssets = this.effect<void>((event$) =>
    event$.pipe(
      switchMap(() => {
        this.patchState((state) => ({ ...state, status: 'loading' }));
        return this.authStore.activeAccount$.pipe(
          filterNullish(),
          switchMap((user) => this.usersRoles.getAll(user.username)),
          first(),
          switchMap((allUserRoles) =>
            this.project.getByIds(
              allUserRoles.users_roles.flatMap((user_roles) =>
                user_roles.roles
                  .filter((role) => role.name === RoleName.Editor)
                  .map(({ resource_id }) => resource_id),
              ),
            ),
          ),
          switchMap((projects) =>
            createChunkedRequests(projects.projects, 5, (chunk) =>
              combineLatest(
                chunk.map((project) =>
                  this.asset
                    .searchAssets(project.project_number)
                    .pipe(map((assets) => assets.existing_assets)),
                ),
              ),
            ),
          ),
          map((assets) => assets.flat().flat()),
          switchMap((assets) =>
            createChunkedRequests(assets, 5, (chunk) =>
              combineLatest(
                chunk.map((asset) =>
                  this.asset.getAsset(asset.id).pipe(catchError(() => EMPTY)),
                ),
              ),
            ),
          ),
          map((assets) => assets.flat()),
          tapResponse(
            (assets) => {
              this.patchState({
                status: 'success',
                data: assets,
                cachedAt: new Date(Date.now()),
              });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                status: 'error',
                error,
              });
              this.notification.open({
                type: 'error',
                title: 'Failed to load collection',
                message: error.error?.detail
                  ? error.error.detail
                  : 'An unexpected error occurred when loading collection, please try again at a later time.',
              });
            },
          ),
        );
      }),
    ),
  );
}
